import React, {useEffect, useState} from 'react';
import {useParams, Link} from 'react-router-dom';
import RatingModal from "../Results/RatingModal/RatingModal";
import getCasinosRating from '../../../api/getCasinoRatingApi';
import appStore from '../../../AppStore';
import AppStore from "../../../AppStore";
//@ts-ignore
import DocumentMeta from 'react-document-meta';

const Rating = () => {
const [meta,setMeta] =  useState({
        title: 'AI rating explained | Insaider',
        description: 'Find out more about the Insaider online casino evaluation model.',
    });

 //@ts-ignore
    let {id} = useParams();

    useEffect(() => {

        getCasinosRating(id, AppStore.locale).then((resp) => {
            appStore.ratingStore.setCasinoRating(resp.data.data);
            setMeta({ 
                title:  resp.data.data.name+' | AI rating explained| Insaider',
                description: resp.data.data.name+' - Find out more about the Insaider online casino evaluation model.'
            });
        });

        return () => {
            appStore.ratingStore.resetCasinoRating()
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    return <>
        <DocumentMeta {...meta} />
        <RatingModal/>
    </>
};

export default Rating;