import React, {useEffect, useMemo, useState} from 'react';
import 'swiper/swiper.scss';
import Mark from '../../SVG/Mark/Mark';
import {Link} from 'react-router-dom';
import Star from '../../SVG/Star/Star';
import CopyAndTerms from '../../CopyAndTerms/CopyAndTerms';
import NewsButton from '../../NewsButton/NewsButton';
import ResultCard, {TResultCard} from './ResultCard/ResultCard';
import FiltersModal from './FilterModal/FiltersModal';
import {observer} from 'mobx-react';
import cn from 'classnames';
import appStore from '../../../AppStore';
import {toJS} from 'mobx';
import Slider from '../../Slider';
import useButton from '../../../hooks/useButton';
import Loader from '../../Loader/Loader';
import {getCasinosListApi} from '../../../api/getCasinosListApi';
import {getFiltersApi} from '../../../api/getFiltersApi';
import parseCasinoResp from '../../../helpers/parseCasinoResp';
import parseFilterResp from '../../../helpers/parseFilterResp';
import getFiltersIds from '../../../helpers/getFiltersIds';
import {HAS_SUBSCRIPTION_KEY, RESULT_FILTER_MODAL} from './constants';
//@ts-ignore
import DocumentMeta from 'react-document-meta';
import getTranslation from '../../../helpers/getTranslation';
import AppStore from "../../../AppStore";
import JoinCard from './GenerateCard/JoinCard';
import saveSubscriptionApi from '../../../api/saveSubscriptionApi';



const Results = () => {
    const meta = {
        title: 'AI online analysis results | Insaider',
        description: 'The results of the analysis of the Insaider AI online casino evaluation system.',
    };

    const subscribed = Boolean(window.localStorage.getItem(HAS_SUBSCRIPTION_KEY));

    const [currentPos, setCurrentPos] = useState<number>(0);
    const [hasSubscription, setHasSubscription] = useState<boolean>(subscribed);

    useEffect(() => {
        appStore.resultsStore.setLoading(true);

        Promise.all([getCasinosListApi({}, AppStore.locale), getFiltersApi(AppStore.locale)])
            .then((res) => {
                const [casinosResp, filtersResp] = res;
                const casinos = parseCasinoResp(casinosResp);
                const filters = parseFilterResp(filtersResp);

                setTimeout(() => {
                    appStore.resultsStore.setLoading(false);
                    appStore.resultsStore.setItems(casinos);
                    appStore.filterStore.setFilters(filters);
                }, 7000);

            }, (error) => {
                console.error(error);
            });
    }, []);

    const closeModal = () => {
        appStore.resultsStore.setModal(null);
    };

    const onFiltersApply = () => {
        const selectedFilters = getFiltersIds(appStore.filterStore.filters);
        closeModal();
        appStore.resultsStore.setLoading(true);

        getCasinosListApi({filters: selectedFilters}, AppStore.locale)
            .then((resp) => {

                const casinos = parseCasinoResp(resp);

                setTimeout(() => {
                    appStore.resultsStore.setLoading(false);
                    appStore.resultsStore.setItems(casinos);
                }, 7000);
            });
    }

    const renderResults = () => {
        return toJS(appStore.resultsStore.items).map((item: TResultCard) => {
            return <ResultCard key={item.id} {...item}  />;
        });
    }

    const saveJoinInfo = (email: string) => {
        saveSubscriptionApi(email, AppStore.locale).then(() => {
            setHasSubscription(true);
            localStorage.setItem(HAS_SUBSCRIPTION_KEY, 'true');
        }, (error) => {
            console.error(error);
        })
    };

    const items = useMemo(() => {

        const itemsToView = renderResults();

        if (!hasSubscription) {
            itemsToView.push(<JoinCard key="joinCard" title={getTranslation('joinTitle')} placeholder={getTranslation('joinEmail')}
                                       text={getTranslation('joinText')} onSave={saveJoinInfo}/>);
        }

        return itemsToView;
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [appStore.resultsStore.items, hasSubscription]);

    useEffect(() => {
        if (hasSubscription) {
            setCurrentPos(items.length -1);
        }
    }, [hasSubscription]);

    const classNames = {
        enter: 'result-filter-btn-enter',
        enterActive: 'result-filter-btn-enter-active',
        enterDone: 'result-filter-btn-enter-done',
        exit: 'result-filter-btn-exit',
        exitActive: 'result-filter-btn-exit-active',
        exitDone: '',
    };

    const renderContent = () => <div className="title-wrapper"><span
        className="btn-title">{getTranslation('resultLinkTitle')}</span><Star
        className="filter-star"/></div>

    const resultButton = useButton(cn("result-filter-btn", {"selected": appStore.filterStore.hasSelected}),
        classNames,
        () => {
            appStore.resultsStore.setModal({type: RESULT_FILTER_MODAL});
        }, renderContent);


    if (appStore.resultsStore.loading) {
        return <Loader className={''}
                       onLoad={() => appStore.resultsStore.setLoading(false)}
                       startTitle={getTranslation('startTitle')}
                       endTitle={getTranslation('endTitle')}/>
    }

    const renderModal = () => {
        if (appStore.resultsStore.modal) {
            switch (appStore.resultsStore.modal.type) {
                case RESULT_FILTER_MODAL:
                    return <FiltersModal onClose={closeModal}
                                         onApply={onFiltersApply}/>;
                default:
                    return null
            }

        } else {
            return null;
        }
    }


    return <div className="results-container">
        <DocumentMeta {...meta} />

        <div className="results">
            <header className="results-header">
                <Link to={`/${appStore.locale}`}><Mark className="insaider-mark flicker"/></Link>
                <NewsButton/>
                {resultButton}
            </header>
            <div className="results-content">
                <Slider items={items}
                        current={currentPos}
                        emptyMessage={getTranslation('NoCasinos')}
                        gap={20}/>
            </div>
            <footer className="results-footer">
                <CopyAndTerms/>
                {/*<SMSQLogo className="smsq-logo-small flicker"/>*/}
            </footer>
        </div>
        {renderModal()}
    </div>
};

export default observer(Results);