import React from 'react';
import './newsCard.scss';
import {useHistory} from 'react-router-dom';
import getTranslation from '../../../../helpers/getTranslation';
import AppStore from '../../../../AppStore';

export type TNewsCard = {
    id: number,
    logo: string,
    name: string,
    title: string,
    text: string,
    url: string,
    news_url : string
}
const cn = require('classnames');
const NewsCard = (props: TNewsCard) => {
    return <div className="news-card">
{props.name ? <header className="news-card-header">
            <figure className="casino-logo slide-down">
                <img src={props.logo} alt={props.logo}/>
            </figure>
            <h3 className="casino-name flicker">
                <a href={props.url}
                   target="_blank"
                   rel="noopener noreferrer">{props.name}</a>
            </h3>
        </header> : null}
        <div className="news-card-content">
            <h2 className="casino-title flicker">
                <a href={props.news_url}
                   target="_blank"
                   rel="noopener noreferrer">{props.title}</a>
            </h2>
            <p className="casino-text slide-down">{props.text}</p>
        </div>
        <footer className="news-card-footer">
            <a href={props.news_url}
               target="_blank"
               rel="noopener"
               className={cn('btn', 'btn_green', 'btn-big', 'play-btn')}>
                <span>{getTranslation('detailBtnText')}</span>
            </a>
        </footer>
    </div>
};

export default NewsCard;