export default function parseNewsResp(resp: any) {
    if (!resp || !resp.data || !resp.data.data ||  !resp.data.data.items || !resp.data.data.items.length) {
        return [];
    }

    return resp.data.data.items.map((item: any) => {
        //const description = item.description.replace(/<\/?div>/ig, '');
       // const descriptionShort = item.descriptionShort.replace(/<\/?div>/ig, '');

        return {
            id: item.id,
            name: item.casino_title,
            logo: item.casino_img,
            title: item.title,
            text: item.description,
            url: item.casino_url,
            news_url: 'news/'+item.url
        }
    });
}