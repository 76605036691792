import React, {useState} from 'react';
import './ratingLabel.scss';
import cn from 'classnames';
import {CSSTransition} from 'react-transition-group';
import getTranslation from '../../../../helpers/getTranslation';

type TRatingLabelProps = {
    value: number,
    className?: string,
    onClick: any,
    href:string
}


const RatingLabel = (props: TRatingLabelProps) => {

    let [start, setStart] = useState(false);

    const classNames = {
        enter: 'rating-label-enter',
        enterActive: 'rating-label-enter-active',
        enterDone: 'rating-label-enter-done',
        exit: 'rating-label-exit',
        exitActive: 'rating-label-exit-active',
        exitDone: '',
    };

    const onClickHandler = () => {
        setStart(true);
    }
    const onExited = () => {
        props.onClick(props.href);
    };

    return <CSSTransition in={start}
                          classNames={classNames}
                          onEntered={() => {
                              setStart(false);
                          }}
                          onExited={onExited}
                          timeout={{
                              appear: 1000,
                              enter: 500,
                              exit: 2000,
                          }}>
        <div className={cn("rating-label slide-down")}
             onClick={onClickHandler}>
            <span className="rating-value">{props.value.toFixed(2)}</span>
            <span className="rating-link"><span>{getTranslation('ai_rating')}</span><span
                className="hide-on-small">{getTranslation('explained')}</span></span>
        </div>
    </CSSTransition>

};

export default RatingLabel