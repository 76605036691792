import React, {useEffect, useState} from 'react';
//import appStore from '../../../AppStore';
import AppStore from "../../../AppStore";
import getTermsApi from '../../../api/getTermsApi';
import getTranslation from '../../../helpers/getTranslation';
//@ts-ignore
import DocumentMeta from 'react-document-meta';

const Terms = () => {
    const [terms, setTerms] = useState(getTranslation('waitPage'));
    const meta = {
        title:  getTranslation('privacyTerms')+'| Insaider',
        description: getTranslation('privacyTerms'),
    };

 useEffect(() => {
        getTermsApi(AppStore.locale).then((resp) => {
           setTerms(resp.data.data);
//console.log(resp.data.data);
        });

        return () => {          
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return <div className="winning-container">
            <DocumentMeta {...meta} />
        <h1 className="winning-title flicker">
            Terms and Conditions
        </h1>
    <div className="cards-contant">
        <div className="cards-container">
                <div dangerouslySetInnerHTML={{ __html: terms }} />
         </div>
     </div>
</div>
};

export default Terms;