import React, {useEffect, useState} from 'react';
import appStore from '../../../AppStore';
import AppStore from "../../../AppStore";
import getNewsDetailsApi from '../../../api/getNewsDetailsApi';
import getTranslation from '../../../helpers/getTranslation';
import Mark from '../../SVG/Mark/Mark';
import './newsDetails.scss';

//@ts-ignore
import DocumentMeta from 'react-document-meta';
import {useParams, Link} from 'react-router-dom';

const NewsDetails = () => {
 //@ts-ignore
    let {url} = useParams();

    const [news, setNews] = useState(getTranslation('waitPage'));
    const [meta,setMeta] =  useState({
        title:  'NEWS | Insaider',
        description: getTranslation('privacyTerms'),
    });

 useEffect(() => {
        getNewsDetailsApi(AppStore.locale,url).then((resp) => {
           setNews(resp.data.data);
            setMeta({ 
                title:  resp.data.data.meta_title+' | Insaider',
                description: resp.data.data.meta_description
            });
        });

        return () => {          
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return <div className="winning-container">
            <DocumentMeta {...meta} />
            <header className="news-header">
                <Link to={`/${appStore.locale}`}><Mark className="insaider-mark flicker"/></Link>
                <h1 className="news-title flicker">
                    {news.title}
                </h1>
            </header>
    <div className="cards-contant">
        <div className="cards-container">
                <div dangerouslySetInnerHTML={{ __html: news.description }} />
         </div>
     </div>
</div>
};

export default NewsDetails;