import React from 'react';
import './newsButton.scss';
import getTranslation from '../../helpers/getTranslation';
import AppStore from '../../AppStore';
import {language} from '../Views/Home/constants';


const NewsButton = () => {

    const lng = AppStore.locale;

    let url_news;

    switch (lng) {
        case language.SWEDISH:
            url_news = '/se/news'

            break;
        case language.FINLAND:
            url_news = '/fi/news'

            break;
        default:
            url_news = '/en/news';
    }
//console.log(url_news);
    return  <div className="news-button"><a href={url_news} target="_blank">
                    <span className="btn-title">{getTranslation('buttonNews')}</span></a>
                </div>
};

export default NewsButton;