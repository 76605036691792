import {makeAutoObservable} from 'mobx';
import FiltersVM from './Components/Views/Results/FilterModal/FiltersVM';
import ResultsStore from './stores/ResultsStore';
import RatingModalVM from './Components/Views/Results/RatingModal/RatingModalVM';
import {DEFAULT_LANGUAGE} from './api/constants';

const PATH_NAME_DELIMITER = '/';

const parseLocationPath = () => {
    const {pathname} = window.location;
    const [_, currentLocale] = pathname.split(PATH_NAME_DELIMITER);
    return currentLocale;
}

class AppStore {

    public filterStore: any;
    public resultsStore: any;
    public ratingStore: RatingModalVM;
    public locale: string;
    public languages: object;
    public loading: boolean;
    public translations: object;
    public terms: string;

    constructor() {
        this.filterStore = new FiltersVM();
        this.resultsStore = new ResultsStore();
        this.ratingStore = new RatingModalVM();
        this.locale = parseLocationPath() || DEFAULT_LANGUAGE;
        this.languages = {};
        this.translations = {};
        this.loading = true;
        this.terms = 'Wait ...';
        makeAutoObservable(this);
    }

    setLanguages (languages: object) {
        this.languages = languages;
    }

    setLoading (loading: boolean) {
        this.loading = loading;
    }

    setTranslations(translations: object) {
        this.translations = translations
    }
    
    setTerms(terms: string) {
        this.terms = terms
    }
    
}

export default new AppStore();