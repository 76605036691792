import React, {useEffect} from 'react';
import './App.scss';
import {BrowserRouter as Router, Route, Switch} from 'react-router-dom';
import Rating from './Components/Views/Rating';
import Results from './Components/Views/Results';
import News from './Components/Views/News';
import Home from './Components/Views/Home/Home';
import Terms from './Components/Views/Terms';
import NewsDetails from './Components/Views/NewsDetails';
import getLanguagesApi from './api/getLanguagesApi';
import {observer} from 'mobx-react';
import AppStore from './AppStore';
import getTranslationsApi from "./api/getTranslationsApi";
import CookieBar from './Components/CookieBar/CookieBar';
import NotFound from './NotFound';

function App() {

    useEffect(() => {

        Promise.all([getLanguagesApi(), getTranslationsApi(AppStore.locale)]).then((resp) => {
            const [languages, translations] = resp;
            //@ts-ignore
            AppStore.setLanguages(languages && languages.data ? languages.data : {});
            //@ts-ignore
            window.translations = translations && translations.data ? translations.data : {};
            AppStore.setLoading(false);
        }, (error) => {
            AppStore.setLoading(false);
            console.log(error);
        });

    }, []);

    if (AppStore.loading) {
        return null;
    }
const langs=Object.keys(AppStore.languages).join('|');
    return <div className="App">
        <Router>
            <Switch>         
                <Route path='/:lng(en|fi|se)/news/:url' component={NewsDetails}/>
                <Route path='/:lng(en|fi|se)/news' component={News}/>
                <Route path='/:lng(en|fi|se)/rating/:id' component={Rating}/>
                <Route path='/:lng(en|fi|se)/results' component={Results}/>
                <Route path='/:lng(en|fi|se)/terms' component={Terms}/>
                <Route path='/:lng(en|fi|se)' component={Home}/>  
                <Route exact path='/' component={Home} />
                <Route component={NotFound}/>           
            </Switch>
        </Router>
        <CookieBar />
    </div>;
}

export default observer(App);
