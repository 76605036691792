import React, {useEffect, useMemo, useState} from 'react';
import 'swiper/swiper.scss';
import Mark from '../../SVG/Mark/Mark';
import {Link} from 'react-router-dom';
import Star from '../../SVG/Star/Star';
import CopyAndTerms from '../../CopyAndTerms/CopyAndTerms';
import NewsCard, {TNewsCard} from './NewsCard/NewsCard';
import {observer} from 'mobx-react';
import cn from 'classnames';
import appStore from '../../../AppStore';
import AppStore from "../../../AppStore";
import {toJS} from 'mobx';
import Slider from '../../Slider';
import useButton from '../../../hooks/useButton';
import Loader from '../../Loader/Loader';
import getNewsApi from '../../../api/getNewsApi';
//@ts-ignore
import DocumentMeta from 'react-document-meta';
import getTranslation from '../../../helpers/getTranslation';
import parseNewsResp from '../../../helpers/parseNewsResp';


const News = () => {
    const meta = {
        title:  getTranslation('titleNews')+' | Insaider',
        description: getTranslation('descNews'),
    };

    const [currentPos, setCurrentPos] = useState<number>(0);

    useEffect(() => {
        appStore.resultsStore.setLoading(true);

        Promise.all([ getNewsApi(AppStore.locale)])
            .then((res) => {
                const [newsResp] = res;
                const news = parseNewsResp(newsResp);
                appStore.resultsStore.setNews(news);

            }, (error) => {
                console.error(error);
            });
    }, []);


    const renderResults = () => {
        return toJS(appStore.resultsStore.news).map((item: TNewsCard) => {
            return <NewsCard key={item.id} {...item}  />;
        });
    }

    const items = useMemo(() => {

        const itemsToView = renderResults();

        return itemsToView;
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [appStore.resultsStore.news]);

    useEffect(() => {
            setCurrentPos(0);
    });

    const classNames = {
        enter: 'result-filter-btn-enter',
        enterActive: 'result-filter-btn-enter-active',
        enterDone: 'result-filter-btn-enter-done',
        exit: 'result-filter-btn-exit',
        exitActive: 'result-filter-btn-exit-active',
        exitDone: '',
    };

    const renderContent = () => <div className="title-wrapper"><span
        className="btn-title">{getTranslation('resultLinkTitle')}</span><Star
        className="filter-star"/></div>

//const isEmptyArray = items => !items?.length;

    return <div className="results-container">
        <DocumentMeta {...meta} />

        <div className="results">
            <header className="results-header">
                <Link to={`/${appStore.locale}`}><Mark className="insaider-mark flicker"/></Link>
            </header>
            <div className="results-content">
                <Slider items={items}
                        current={currentPos}
                        emptyMessage={getTranslation('NoNews')}
                        gap={20}/>
            </div>
            <footer className="results-footer">
                <CopyAndTerms/>
                {/*<SMSQLogo className="smsq-logo-small flicker"/>*/}
            </footer>
        </div>
    </div>
};

export default observer(News);