import Modal from '../../../Modal/Modal';
import Close from '../../../SVG/Close/Close';
import './ratingModal.scss';
import RatingItem from './RatingItem';
import Dice from '../../../SVG/Dice/Dice';
import appStore from '../../../../AppStore';
import {observer} from 'mobx-react';
import {toJS} from 'mobx';
import ItemSidebar from './ItemSidebar';
import {useParams, Link} from 'react-router-dom';
import getTranslation from "../../../../helpers/getTranslation";
import AppStore from "../../../../AppStore";
import CopyAndTerms from '../../../CopyAndTerms/CopyAndTerms';


const RatingModal = () => {
    //@ts-ignore
    let {id} = useParams();

    let splitName = '';
    let splitNameLast = '';
    let nameArr
    if (appStore.ratingStore.rating && appStore.ratingStore.rating.name) {
        nameArr = appStore.ratingStore.rating.name.split(' ');
        if (nameArr.length > 1) {
            splitNameLast = nameArr.pop();
            splitName = nameArr.join(" ");
        } else {
            splitName = appStore.ratingStore.rating.name;
        }
    }

    return <Modal>
        <div className="rating-modal-container">

            {appStore.ratingStore.selected && <ItemSidebar {...toJS(appStore.ratingStore.selected)} onClose={() => {
                appStore.ratingStore.setSelected(null)
            }}/>}
            <header className="rating-modal-header">
                <div>
                    <h1 className="rating-modal-title flicker">{getTranslation('ratingExpalinerTitle')}</h1>
                    {/*<h1 className="rating-modal-title flicker">explainer</h1>*/}
                </div>

                <Link className="rating-modal-close-btn fade" to={`/${AppStore.locale}/results`}><Close
                    className="rating-modal-close-ico"/></Link>
            </header>


            {appStore.ratingStore.rating && <>
                <div className="casino-rating casino-rating-top">
                    <Dice className="casino-rating-dice"/>
                    <span className="casino-rating-title">{getTranslation('scoreTitle')}</span>
                    <span className="casino-rating-score">{`${appStore.ratingStore.rating.score}%`}</span>
                </div>
                <main className="rating-modal-content">
                    {appStore.ratingStore.rating.props.map((prop: any) => {
                        return <div key={`${prop.id}-prop`} className="rating-modal-content-wrapper">
                            <h4 className="rating-modal-subtitle">{getTranslation(prop.name)}</h4>
                            <div className="items-container">
                                {prop.items.map((item: any) => {
                                    let value = item.value;
                                    if (item.type === 'DOUBLE') {
                                        value = item.value === 1 ? 3 : 1
                                    }
                                    return <RatingItem key={`${item.id}-rating-item-${prop.id}`} name={item.name}
                                                       value={value}
                                                       onClick={() => appStore.ratingStore.setSelected(item)}/>
                                })}
                            </div>

                        </div>
                    })}
                </main>
                <footer className="rating-modal-footer">
                    <div className="casino-info">
                        <figure><img src={appStore.ratingStore.rating.logo} alt="logo"/></figure>
                        <div className="casino-info-name">
                            <div>{splitName}</div>
                            <div>{splitNameLast}</div>
                        </div>
                    </div>
                    <div className="casino-rating">
                        <Dice className="casino-rating-dice"/>
                        <span className="casino-rating-title">{getTranslation('scoreTitle')}</span>
                        <span className="casino-rating-score">{`${appStore.ratingStore.rating.score}%`}</span>
                    </div>
                    <a className="casino-rating-link"
                       href={appStore.ratingStore.rating.url}
                       target="_blank" rel="noopener noreferrer">{getTranslation('playBtnText')}</a>
                </footer>
                <div className="casino-rating-disclaimer"><span className="casino-rating-disclaimer-links">18+, 
                            <a href="https://www.stodlinjen.se" target="_blank" rel="noopener noreferrer">stodlinjen.se.</a>
                    &nbsp;<a href="https://www.stodlinjen.se" target="_blank" rel="noopener noreferrer">{getTranslation('disclaimerText')}</a>&nbsp;</span>
                    <CopyAndTerms/>
                </div>
            </>}

        </div>
    </Modal>
}

export default observer(RatingModal);