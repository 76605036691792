import {API_URL_BASE} from "./constants_local";

export  {API_URL_BASE};
export const CASINOS_SLUG = 'get-casinos';
export const FILTERS_SLUG = 'get-smart-filters';
export const CASINO_SLUG = 'get-casino';
export const LANGUAGES_SLUG = 'translations-available';
export const TRANSLATIONS_SLUG = 'translations';
export const DEFAULT_LANGUAGE = 'en';
export const SAVE_SUBSCRIPTION_SLUG = 'add_subscription';
export const TERMS_SLUG = 'get-terms';
export const NEWS_SLUG = 'get-news';
export const NEWS_DETAILS_SLUG = 'get-details-news';

